<template>

  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="sk-logo-maintenance">
        <vuexy-logo />
      </div>
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ i18nT(`The platform is undergoing maintenance`) }} 🛠
        </h2>

        <p class="mb-3">
          {{ i18nT(`Sorry for the inconvenience but we're performing some maintenance at the moment.`) }}
          <br />
          {{i18nT(`Please check back soon.`)}}
        </p>
        <b-progress :value="startInterval" :max="maxInterval" class="mb-3"></b-progress>
        <h4 v-if="remainingTime>0">{{i18nT(`This page will automatically reload in`)}} {{remainingTime.toFixed(0)}} {{i18nT(`seconds`)}}</h4>

<!--        <b-form-->
<!--          inline-->
<!--          class="justify-content-center row m-0 mb-2"-->
<!--          @submit.prevent-->
<!--        >-->
<!--          <b-form-input-->
<!--            id="notify-email"-->
<!--            class="col-12 col-md-5 mb-1 mr-md-2"-->
<!--            placeholder="john@example.com"-->
<!--          />-->

<!--          <b-button-->
<!--            variant="primary"-->
<!--            class="mb-1 btn-sm-block"-->
<!--            type="submit"-->
<!--          >-->
<!--            Notify-->
<!--          </b-button>-->
<!--        </b-form>-->

        <!-- img -->
<!--        <b-img-->
<!--          fluid-->
<!--          :src="imgUrl"-->
<!--          alt="Under maintenance page"-->
<!--        />-->
      </div>
    </div>
  </div>
<!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BFormInput, BButton, BForm, BImg, BProgressBar,BProgress
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    VuexyLogo,
    BProgress
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
      maxInterval: 100,
      startInterval: 100,
      timer: null,
      timeResolution: 100,
      remainingTime: 30,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {

    this.maxInterval = this.remainingTime * 1000 / this.timeResolution;
    this.startInterval = this.maxInterval;


    // setTimeout(() => {
    //   window.location.href = '/'
    // }, 5000)

    this.timer = setInterval(() => {
      if (this.startInterval > -1) {
        this.startInterval -= 1;
        this.remainingTime -= this.timeResolution / 1000;
      }
    }, this.timeResolution)
  },
  watch: {
    startInterval(val) {
      if (val < 0) {
        window.location.href = '/'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.sk-logo-maintenance {
  text-align: center;
  max-width: 300px;
  margin: 2rem auto;

}
</style>
